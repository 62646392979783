.pagination {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 20px 0 40px;
    padding: 0;
}
.pagination__item {
    margin: 0 2px;
}
.pagination__link {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 30px;
    justify-content: center;
    line-height: 1;
    text-decoration: none;
    width: 30px;
    &:hover {
        background-color: fade(@primary-color, 50%);
        color: #fff;
    }
    .active & {
        background-color: @primary-color;
        color: #fff;
    }
}
