html {
  font-size: 18px;
}
body {
  color: #10293D;
  font-family: 'Fira Sans', sans-serif;
  line-height: 1.25;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  body:not(.template-homepage) {
    padding-top: 120px;
  }
}
body.template--form {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
}
.template--form main {
  flex: 1;
  margin: 0 0 60px;
}
.richtext-image {
  border-radius: 8px;
  display: block;
  height: auto;
  max-width: 100%;
}
.richtext-image.full-width {
  margin: 0 auto 30px;
}
.richtext-image.right {
  float: right;
  margin: 0 0 30px 30px;
}
.richtext-image.left {
  float: left;
  margin: 0 30px 30px 0;
}
.container {
  margin: auto;
  max-width: 1080px;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .container {
    padding: 0 40px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1170px;
  }
}
.container--middle {
  max-width: 1000px;
}
.container--small {
  max-width: 800px;
}
.page__narrow {
  margin: auto;
  max-width: 1080px;
  padding: 0 15px;
}
@media (min-width: 1280px) {
  .page__narrow {
    max-width: 1170px;
  }
}
.hidden {
  display: none;
}
div,
dl,
dt,
dd,
ul,
ol,
li,
h3,
h4,
h5,
h6,
pre,
form,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
p {
  line-height: 1.6;
  margin: 0 0 25px;
  text-rendering: optimizeLegibility;
}
em,
i {
  font-style: italic;
  line-height: inherit;
}
strong,
b {
  font-weight: 700;
  line-height: inherit;
}
small {
  font-size: 82%;
  line-height: inherit;
}
h1 {
  color: inherit;
  font-family: 'Fira Sans', sans-serif;
  letter-spacing: 1.5px;
  line-height: 1.2;
  margin: 0 0 25px;
  text-rendering: optimizeLegibility;
  text-align: center;
}
.suiteHeaderInfo h1 {
  text-align: left;
}
h1 small {
  color: #cacaca;
  line-height: 0;
}
a {
  color: #F80D7E;
  cursor: pointer;
  line-height: inherit;
  text-decoration: underline;
  transition: 0.3s;
}
a:hover,
a:focus {
  color: #504e4e;
}
a img {
  border: 0;
}
.btn {
  background-color: #F80D7E;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 20px;
  padding: 12px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;
}
.btn:hover,
.btn:focus {
  background-color: rgba(248, 13, 126, 0.75);
  color: #fff;
}
.btn--close {
  background: transparent;
  border-radius: 50%;
  border: 0;
  color: #10293D;
  cursor: pointer;
  float: right;
  height: 50px;
  line-height: 1;
  margin: 0 0 20px 20px;
  padding: 5px;
  transition: 0.15s ease;
  width: 50px;
}
.btn--close:hover {
  background-color: #F80D7E;
  color: #fff;
}
hr {
  border-bottom: 1px solid #cacaca;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  clear: both;
  height: 0;
  margin: 1rem auto;
  max-width: 1200px;
}
ul,
ol,
dl {
  margin-bottom: 1rem;
}
li {
  font-size: inherit;
}
ul {
  list-style-type: disc;
  margin-left: 1.25rem;
}
ol {
  margin-left: 1.25rem;
}
ul ul,
ul ol,
ol ul,
ol ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
}
.soclink {
  display: flex;
  list-style: none;
  margin: 0 0 0 25px;
  padding: 0;
}
.soclink__item {
  align-items: center;
  display: flex;
  margin-left: 15px;
}
.soclink__item__link {
  display: flex;
}
.soclink svg:hover {
  opacity: 0.4;
}
dl {
  margin-bottom: 1rem;
}
dl dt {
  font-weight: 700;
  margin-bottom: 0.3rem;
}
blockquote {
  border-left: 1px solid #cacaca;
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
}
blockquote,
blockquote p {
  color: #8a8a8a;
  line-height: 1.6;
}
cite {
  color: #8a8a8a;
  display: block;
  font-size: 0.82rem;
}
cite::before {
  content: '\2014 \0020';
}
abbr {
  border-bottom: 1px dotted #000;
  color: #10293D;
  cursor: help;
}
code {
  background-color: #e6e6e6;
  border: 1px solid #cacaca;
  color: #000;
  font-family: Consolas, 'Liberation Mono', Courier, monospace;
  font-weight: 400;
  padding: 0.125rem 0.3125rem 0.0625rem;
}
kbd {
  background-color: #e6e6e6;
  color: #000;
  font-family: Consolas, 'Liberation Mono', Courier, monospace;
  margin: 0;
  padding: 0.125rem 0.25rem 0;
}
.hiddenStructure {
  display: none;
}
.page__header {
  background-color: #fff;
  transition: all 0.4s;
}
@media (min-width: 768px) {
  .page__header--fixed {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}
.logo {
  display: block;
  padding: 15px 7px 4px;
}
.page__header .logo {
  background-color: #F80D7E;
}
.page__header .logo:hover {
  background-color: rgba(248, 13, 126, 0.75);
}
@media (min-width: 768px) {
  .navbar {
    display: flex;
    font-size: 18px;
  }
  .page__header .navbar {
    justify-content: center;
  }
  .page__footer .navbar {
    align-items: center;
    justify-content: space-between;
  }
}
.nav {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .nav {
    align-items: center;
    display: flex;
  }
}
.nav.open {
  background: #f80d7e;
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  padding: 0 15px 15px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}
.nav__item {
  display: none;
}
.open .nav__item {
  display: block;
}
@media (min-width: 768px) {
  .nav__item {
    display: block;
  }
}
.nav__item--logo {
  display: inline-block;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .nav__item--logo {
    display: block;
    margin: 0 30px;
    order: 2;
  }
}
.nav__item--logo.open {
  display: inline-block;
}
@media (min-width: 768px) {
  .nav__item:nth-child(2) {
    order: 0;
  }
}
@media (min-width: 768px) {
  .nav__item:nth-child(3) {
    order: 1;
  }
}
@media (min-width: 768px) {
  .nav__item:nth-child(4) {
    order: 3;
  }
}
@media (min-width: 768px) {
  .nav__item:nth-child(5) {
    order: 4;
  }
}
.nav__link {
  color: #fff;
  display: block;
  font-size: 30px;
  padding: 10px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
}
@media (min-width: 768px) {
  .nav__link {
    color: #161d33;
    font-size: 18px;
    min-width: 100px;
    padding: 0 15px;
  }
}
.nav__link:hover {
  color: rgba(255, 255, 255, 0.6);
}
@media (min-width: 768px) {
  .nav__link:hover {
    color: #F80D7E;
  }
}
.page__footer .nav__link {
  color: #fff;
}
.page__footer .nav__link:last-child {
  padding-right: 0;
}
.nav__link--active {
  color: rgba(255, 255, 255, 0.6);
}
@media (min-width: 768px) {
  .nav__link--active {
    color: #F80D7E;
  }
}
.navbar__toggle {
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  padding: 15px;
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 2;
}
.navbar__toggle.open {
  color: #fff;
  position: fixed;
}
@media (min-width: 768px) {
  .navbar__toggle {
    display: none;
  }
}
.navbar__toggle__text {
  display: none;
}
.open .navbar__toggle__text {
  display: block;
  margin-right: 8px;
}
.navbar__toggle svg {
  display: block;
}
.page__footer {
  position: relative;
}
.page__footer abbr {
  color: #fff;
}
.page__footer .navbar {
  color: #fff;
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .page__footer .navbar {
    grid-template-columns: auto auto;
  }
}
.page__footer .logo {
  order: 1;
  text-align: center;
  padding: 0;
}
@media (min-width: 768px) {
  .page__footer .logo {
    text-align: left;
    order: 0;
  }
}
@media (min-width: 768px) {
  .container--footer {
    display: grid;
    grid-gap: 200px;
    grid-template-columns: 5fr 2fr;
  }
}
.footer__contact {
  padding: 20px 0 0;
}
@media (min-width: 768px) {
  .footer__contact {
    align-items: center;
    background-image: url("/static/images/suit-boichuk.png");
    background-position: 60% calc(100% - 150px);
    background-repeat: no-repeat;
    background-size: auto calc(100% - 150px);
    display: flex;
    margin-bottom: -150px;
  }
}
.template--form .footer__contact {
  display: none;
}
.footer__contact h2 {
  text-align: center;
  margin: 0 0 30px;
}
@media (min-width: 768px) {
  .footer__contact h2 {
    margin: 0 0 50px 40px;
    text-align: left;
  }
}
.form--contact {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 30px 40px 40px;
}
.btnContactForm {
  background-color: #df194e;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 4px;
  line-height: 24px;
  margin: 50px auto 0;
  padding: 12px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  width: 240px;
}
.footer__formInfo {
  padding: 40px 0 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .footer__formInfo {
    align-items: center;
    display: flex;
    padding: 0 0 150px;
    text-align: left;
  }
}
.contact__info svg {
  margin: 0 0 18px;
}
.contact__phone {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.12em;
  line-height: 124.4%;
  text-decoration: none;
}
.contact__phone svg {
  margin-right: 10px;
}
.footer__nav {
  background-color: #161d33;
  clear: both;
  font-weight: 300;
  padding: 40px 0;
}
@media (min-width: 768px) {
  .footer__nav {
    padding: 190px 0 52px;
  }
}
.template--form .footer__nav {
  padding: 52px 0;
}
.footer__nav nav {
  display: none;
}
@media (min-width: 768px) {
  .footer__nav nav {
    display: block;
  }
}
.footer__connect {
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .footer__connect {
    float: right;
    margin-top: -125px;
  }
}
@media (min-width: 768px) {
  .template--form .footer__connect {
    display: none;
  }
}
.colophon {
  margin: 30px 0;
  order: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .colophon {
    margin: 0;
    text-align: left;
    order: 1;
  }
}
label {
  color: #c4c4c4;
  display: inline-block;
  font-size: 14px;
  line-height: 150%;
  padding: 10px 0 20px;
  pointer-events: none;
  position: relative;
  transition: 0.5s;
}
textarea,
input {
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 0 0 1px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  outline: none;
  padding: 10px 0;
  position: absolute;
  transition: 0.5s;
  width: 100%;
}
textarea {
  max-height: 125px;
}
.inputBox {
  margin: 20px 0;
  position: relative;
  width: 100%;
}
.inputBox.active label {
  transform: translateY(-30px);
}
.form__visual {
  display: grid;
  grid-gap: 60px;
  grid-template-columns: 1fr;
  margin: 60px 0 0;
}
@media (min-width: 768px) {
  .form__visual {
    grid-gap: 60px;
    grid-template-columns: 3fr 2fr;
  }
}
.template--form .form__fields {
  margin-top: -40px;
}
.pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 20px 0 40px;
  padding: 0;
}
.pagination__item {
  margin: 0 2px;
}
.pagination__link {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  line-height: 1;
  text-decoration: none;
  width: 30px;
}
.pagination__link:hover {
  background-color: rgba(248, 13, 126, 0.5);
  color: #fff;
}
.active .pagination__link {
  background-color: #F80D7E;
  color: #fff;
}
.popup {
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.9);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
}
.popup__container {
  background-color: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.18);
  margin: 15px;
  max-width: 100%;
  padding: 30px;
  position: relative;
}
@media (min-width: 768px) {
  .popup__container {
    margin: 0;
    max-width: 400px;
  }
}
.popup__container h2 {
  color: #F80D7E;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.1;
  margin: 0 0 20px;
}
@media (min-width: 768px) {
  .cards--suits {
    column-count: 2;
    column-gap: 30px;
    margin-top: 60px;
  }
  .template-homepage .cards--suits {
    margin-top: 0;
  }
}
.cards--suits__footer {
  display: inline-block;
  margin: 40px 0;
  text-align: center;
  width: 100%;
}
.cards__header {
  margin-top: -175px;
}
@media (min-width: 768px) {
  .cards__header {
    margin-bottom: 50px;
    margin-top: -200px;
  }
}
.card--suit {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
  display: inline-block;
  margin: 0 0 30px;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-out;
  width: 100%;
}
@media (min-width: 768px) {
  .card--suit {
    box-shadow: none;
  }
}
.card--suit:hover {
  background: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.18);
}
@media (min-width: 768px) {
  .card--suit:hover {
    transform: translateY(-10px);
  }
}
.card--suit:hover .card__img {
  transform: scale(1.02);
}
.card__visual {
  overflow: hidden;
}
.card__img {
  display: block;
  height: auto;
  margin: auto;
  transition: 0.3s ease-out;
  width: 100%;
}
.card__header {
  padding: 30px 30px 45px;
}
.card__type {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.card__title {
  font-size: 21px;
  line-height: 26px;
}
.card__link {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.slick-slider {
  box-sizing: border-box;
  display: block;
  position: relative;
  touch-action: pan-y;
  user-select: none;
}
.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
}
.slick-track::before,
.slick-track::after {
  content: "";
  display: table;
}
.slick-track::after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  border: 1px solid transparent;
  display: block;
  height: auto;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-arrow {
  background-color: transparent;
  border: 0;
  bottom: 50%;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  height: 59px;
  outline: none;
  padding: 0;
  position: absolute;
  width: 25px;
  z-index: 1;
}
.slick-arrow:hover,
.slick-arrow:focus {
  color: #F80D7E;
}
.slick-arrow.slick-disabled {
  color: #000;
  opacity: 0.4;
}
.slick-next {
  right: 0;
}
.slick-prev {
  left: 0;
}
.slick-dots {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 30px 0 0;
  padding: 0;
}
.slick-dots button {
  background-color: #ccc;
  border-radius: 50%;
  border: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 10px;
  line-height: 0;
  margin: 0 4px;
  outline: none;
  overflow: hidden;
  padding: 0;
  width: 10px;
}
.slick-dots .slick-active button {
  background-color: #F80D7E;
}
.suitHero__slider {
  order: 1;
}
@media (min-width: 768px) {
  .suitHero__slider {
    order: 0;
  }
}
.suitHero__slider img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
.slick-next--suitHero {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  left: auto;
  outline: none !important;
  padding: 0;
  position: absolute;
  right: -15px;
  top: calc(50% - 45px);
}
.slick-next--suitHero:hover,
.slick-next--suitHero:focus,
.slick-next--suitHero:active {
  outline: none !important;
}
.slick-next--suitHero img {
  outline: none !important;
}
.slick-next--suitHero img:hover,
.slick-next--suitHero img:focus,
.slick-next--suitHero img:active {
  outline: none !important;
}
.suitHero__sliderNav {
  display: none;
}
@media (min-width: 768px) {
  .suitHero__sliderNav {
    display: block;
  }
}
.suitHero__sliderNav .slick-track {
  margin-left: -15px;
  margin-right: -15px;
}
.suitHero__sliderNav .slick-slide {
  margin: 0 15px;
  transition: 0.5s ease;
}
.suitHero__sliderNav .slick-slide img {
  height: auto;
  max-width: 100%;
}
.suitHero__sliderNav .slick-current {
  display: none;
}
@media (min-width: 768px) {
  .sliderTestimonial {
    padding: 0 50px;
  }
}
@media (min-width: 768px) {
  .sliderTestimonial .slick-list {
    margin: 0 -15px;
  }
}
.sliderTestimonial__item {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
}
@media (min-width: 768px) {
  .sliderTestimonial__item {
    margin: 0 15px;
  }
}
@media (min-width: 1024px) {
  .sliderTestimonial__item {
    display: grid !important;
    grid-gap: 30px;
    grid-template-columns: 100px auto;
    text-align: left;
  }
}
.sliderTestimonial__item__image {
  border-radius: 50%;
  height: 100px;
  margin: 0 auto 25px;
  overflow: hidden;
  width: 100px;
}
.sliderTestimonial__item__position {
  color: rgba(0, 0, 0, 0.4);
  margin: 0 0 15px;
}
.photo__circle_suit {
  background-color: #f0f0f0;
  padding: 1rem;
}
.threesixty {
  cursor: grab;
  margin: 0 auto;
  position: relative;
  user-select: none;
}
.threesixty::after {
  background-image: url("/static/images/360.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 50px auto;
  content: "";
  height: 40px;
  left: 10px;
  opacity: 0.2;
  position: absolute;
  top: 10px;
  width: 50px;
}
.threesixty:active {
  cursor: grabbing;
}
.g-hub {
  color: #444;
  font-size: 0.9em;
}
.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 200px;
  width: 100%;
}
.button {
  appearance: none;
  border: none;
  cursor: pointer;
  padding: 40px 7px 5px;
  position: relative;
}
.button::before,
.button::after {
  border-left: 3px solid #000;
  border-top: 3px solid #000;
  content: '';
  height: 20px;
  left: 50%;
  position: absolute;
  top: 10px;
  transform: translate(-45%) rotate(-45deg);
  width: 20px;
}
.button::after {
  transform: translate(5%) rotate(-45deg);
}
#next {
  /* stylelint-disable-line */
}
#next::before {
  transform: translate(-90%) rotate(135deg);
}
#next::after {
  transform: translate(-50%) rotate(135deg);
}
.suiteHeader {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 100%;
  margin: 0 0 40px;
}
@media (min-width: 768px) {
  .suiteHeader {
    grid-template-columns: 45% 2fr;
  }
}
.suiteHeaderVisual {
  display: flex;
  flex-direction: column;
  order: 0;
}
@media (min-width: 768px) {
  .suiteHeaderVisual {
    order: 1;
  }
}
.suiteHeaderInfo {
  flex: 1;
}
.listingConnectLinks {
  display: flex;
  list-style: none;
  margin: 0 0 25px;
  padding: 0;
}
.listingConnectLinks li:not(:last-child) {
  margin-right: 30px;
}
.backToCollection {
  display: inline-block;
  letter-spacing: 1px;
  margin: 0 0 30px;
  text-decoration: none;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .backToCollection {
    margin: 0 0 50px;
  }
}
.backToCollection svg {
  margin: 0 15px 0 0;
}
.section--intro {
  position: relative;
}
.section__narrow {
  margin: auto;
  max-width: 1170px;
  padding: 0 15px;
}
.section__narrow--testimonials {
  max-width: 1275px;
}
.section__narrow11 {
  box-sizing: border-box;
  padding: 0 15px;
  position: relative;
}
@media (min-width: 768px) {
  .section__narrow11 {
    display: flex;
    min-height: 100vh;
  }
}
.sec1-col1 {
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .sec1-col1 {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    width: calc(50% + 88px);
  }
}
.sec1-col1 img {
  height: auto;
  width: auto;
}
@media (max-width: 767px) {
  .sec1-col1 img {
    margin-bottom: 12px;
    margin-top: -70px;
    max-width: 90%;
  }
}
@media (min-width: 768px) {
  .sec1-col1 img {
    height: calc(100vh - 130px);
    position: absolute;
    right: 58px;
  }
}
.sec1-col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 768px) {
  .sec1-col2 {
    padding: 100px 0;
  }
}
.sec1-col2 h1 {
  font-size: 56px;
  font-weight: 900;
  letter-spacing: 0.05em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .sec1-col2 h1 {
    font-size: 96px;
    text-align: right;
  }
}
.sec1-col2 .btn {
  display: block;
  margin: 0 auto 40px;
  max-width: 190px;
}
@media (min-width: 768px) {
  .sec1-col2 .btn {
    margin: 0 0 25px auto;
  }
}
.sec__desc--intro {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 40px;
  margin-bottom: 35px;
  text-align: center;
}
@media (min-width: 768px) {
  .sec__desc--intro {
    font-size: 30px;
    margin-bottom: 45px;
    text-align: right;
  }
}
.sec__desc--contact {
  text-align: center;
}
.section--suits {
  background-image: url("/static/images/icons/icon-logo.svg");
  background-position: -130px 102%;
  background-repeat: no-repeat;
  background-size: 600px auto;
  padding-bottom: 30px;
  padding-top: 230px;
  position: relative;
}
@media (min-width: 768px) {
  .section--suits {
    background-position: -200px 120%;
    background-size: 100% auto;
    padding-bottom: 80px;
  }
}
.section--suits::before {
  background-color: #fff;
  box-shadow: 0 -60px 40px -30px rgba(0, 0, 0, 0.06);
  content: "";
  height: 100px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: skewY(12deg);
  width: 100%;
}
.section--suits .section__narrow {
  max-width: 1000px;
  position: relative;
}
.section__footer {
  text-align: center;
}
.section__title {
  color: #161d33;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 36px;
  margin: 0 0 30px;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .section__title {
    font-size: 40px;
    line-height: 50px;
    margin: 0 0 40px;
    text-align: left;
  }
}
.section__title--aboutInfo {
  margin: 0 0 20px;
}
@media (min-width: 768px) {
  .section__title--aboutInfo {
    margin: 0 0 40px;
  }
}
.section--testimonials {
  background-color: #f2f4f4;
  background-image: url("/static/images/icons/icon-logo.svg");
  background-position: 110% 100%;
  background-repeat: no-repeat;
  padding: 45px 0;
}
@media (min-width: 768px) {
  .section--testimonials {
    padding: 60px 0 80px;
  }
}
.section--about {
  padding: 45px 0;
}
@media (min-width: 768px) {
  .section--about {
    padding: 60px 0;
  }
}
.section__title--aboutInfo {
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .aboutInfo {
    align-items: center;
    display: grid;
    grid-gap: 45px;
    grid-template-columns: 450px auto;
  }
}
.aboutInfo__image {
  margin-bottom: 25px;
}
.aboutInfo__image img {
  display: block;
  height: auto;
  margin: auto;
  max-width: 100%;
  width: auto;
}
.aboutInfo__readMoreVisual {
  text-align: center;
}
@media (min-width: 768px) {
  .aboutInfo__readMoreVisual {
    text-align: left;
  }
}
