.popup {
    align-items: center;
    backdrop-filter: blur(10px);
    background-color: fade(#fff, 90%);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;
}
.popup__container {
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.18);
    margin: 15px;
    max-width: 100%;
    padding: 30px;
    position: relative;
    @media (min-width: @tablet) {
        margin: 0;
        max-width: 400px;
    }
    h2 {
        color: @primary-color;
        font-size: 40px;
        font-weight: bold;
        line-height: 1.1;
        margin: 0 0 20px;
    }
}
