label {
    color: #c4c4c4;
    display: inline-block;
    font-size: 14px;
    line-height: 150%;
    padding: 10px 0 20px;
    pointer-events: none;
    position: relative;
    transition: 0.5s;
}
textarea,
input {
    border-color: #e1e1e1;
    border-style: solid;
    border-width: 0 0 1px;
    font-family: @sans-serif-fonts;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    outline: none;
    padding: 10px 0;
    position: absolute;
    transition: 0.5s;
    width: 100%;
}
textarea {
    max-height: 125px;
}

.inputBox {
    margin: 20px 0;
    position: relative;
    width: 100%;
    &.active label {
        transform: translateY(-30px);
    }
}

// Form
.form__visual {
    display: grid;
    grid-gap: 60px;
    grid-template-columns: 1fr;
    margin: 60px 0 0;
    @media (min-width: @tablet) {
        grid-gap: 60px;
        grid-template-columns: 3fr 2fr;
    }
}
.form__fields {
    .template--form & {
        margin-top: -40px;
    }
}
