html {
    font-size: @global-font-size;
}
body {
    color: @global-font-color;
    font-family: @sans-serif-fonts;
    line-height: 1.25;
    margin: 0;
    padding: 0;
    &:not(.template-homepage) {
        @media (min-width: 768px) {
            padding-top: 120px;
        }
    }
    &.template--form {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        box-sizing: border-box;
    }
}
main {
    .template--form & {
        flex: 1;
        margin: 0 0 60px;
    }
}
// Images style
.richtext-image {
    border-radius: 8px;
    display: block;
    height: auto;
    max-width: 100%;
    &.full-width {
        margin: 0 auto 30px;
    }
    &.right {
        float: right;
        margin: 0 0 30px 30px;
    }
    &.left {
        float: left;
        margin: 0 30px 30px 0;
    }
}

// Container
.container {
    margin: auto;
    max-width: 1080px;
    padding: 0 15px;
    @media (min-width: @tablet) {
        padding: 0 40px;
    }
    @media (min-width: 1280px) {
        max-width: 1170px;
    }
    &--middle {
        max-width: 1000px;
    }
    &--small {
        max-width: 800px;
    }
}
.page__narrow {
    margin: auto;
    max-width: 1080px;
    padding: 0 15px;
    @media (min-width: 1280px) {
        max-width: 1170px;
    }
}

// Utility
.hidden {
    display: none;
}
