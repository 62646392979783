.page__header {
    background-color: #fff;
    transition: all 0.4s;
    &--fixed {
        @media (min-width: 768px) {
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 2;
        }
    }
}

// Logo
.logo {
    display: block;
    padding: 15px 7px 4px;
    .page__header & {
        background-color: @primary-color;
        &:hover {
            background-color: fade(@primary-color, 75%);
        }
    }
}

// Navbar
.navbar {
    @media (min-width: 768px) {
        display: flex;
        font-size: 18px;
        .page__header & {
            justify-content: center;
        }
        .page__footer & {
            align-items: center;
            justify-content: space-between;
        }
    }
}
.nav {
    list-style: none;
    margin: 0;
    padding: 0;
    @media (min-width: 768px) {
        align-items: center;
        display: flex;
    }
    &.open {
        background: #f80d7e;
        bottom: 0;
        box-sizing: border-box;
        left: 0;
        padding: 0 15px 15px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1;
    }
}
.nav__item {
    display: none;
    .open & {
        display: block;
    }
    @media (min-width: 768px) {
        display: block;
    }
    &--logo {
        display: inline-block;
        margin-bottom: 25px;
        @media (min-width: 768px) {
            display: block;
            margin: 0 30px;
            order: 2;
        }
        &.open {
            display: inline-block;
        }
    }
    &:nth-child(2) {
        @media (min-width: 768px) {
            order: 0;
        }
    }
    &:nth-child(3) {
        @media (min-width: 768px) {
            order: 1;
        }
    }
    &:nth-child(4) {
        @media (min-width: 768px) {
            order: 3;
        }
    }
    &:nth-child(5) {
        @media (min-width: 768px) {
            order: 4;
        }
    }
}
.nav__link {
    color: #fff;
    display: block;
    font-size: 30px;
    padding: 10px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    @media (min-width: 768px) {
        color: #161d33;
        font-size: 18px;
        min-width: 100px;
        padding: 0 15px;
    }
    &:hover {
        color: fade(#fff, 60%);
        @media (min-width: 768px) {
            color: @primary-color;
        }
    }
    .page__footer & {
        color: #fff;
        &:last-child {
            padding-right: 0;
        }
    }
    &--active {
        color: fade(#fff, 60%);
        @media (min-width: 768px) {
            color: @primary-color;
        }
    }
}
.navbar__toggle {
    align-items: center;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    padding: 15px;
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 2;
    &.open {
        color: #fff;
        position: fixed;
    }
    @media (min-width: 768px) {
        display: none;
    }
    &__text {
        display: none;
        .open & {
            display: block;
            margin-right: 8px;
        }
    }
    svg {
        display: block;
    }
}
