// Section
.section {
    &--intro {
        position: relative;
    }
}
.section__narrow {
    margin: auto;
    max-width: 1170px;
    padding: 0 15px;
    &--testimonials {
        max-width: 1275px;
    }
}
.section__narrow11 {
    box-sizing: border-box;
    padding: 0 15px;
    position: relative;
    @media (min-width: 768px) {
        display: flex;
        min-height: 100vh;
    }
}
.sec1-col1 {
    position: relative;
    text-align: center;
    @media (min-width: 768px) {
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;
        width: calc(50% + 88px);
    }
    img {
        height: auto;
        width: auto;
        @media (max-width: 767px) {
            margin-bottom: 12px;
            margin-top: -70px;
            max-width: 90%;
        }
        @media (min-width: 768px) {
            height: calc(100vh - 130px);
            position: absolute;
            right: 58px;
        }
    }
}
.sec1-col2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 768px) {
        padding: 100px 0;
    }
    h1 {
        font-size: 56px;
        font-weight: 900;
        letter-spacing: 0.05em;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        @media (min-width: 768px) {
            font-size: 96px;
            text-align: right;
        }
    }
    .btn {
        display: block;
        margin: 0 auto 40px;
        max-width: 190px;
        @media (min-width: @tablet) {
            margin: 0 0 25px auto;
        }
    }
}
.sec__desc--intro {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 40px;
    margin-bottom: 35px;
    text-align: center;
    @media (min-width: 768px) {
        font-size: 30px;
        margin-bottom: 45px;
        text-align: right;
    }
}
.sec__desc--contact {
    text-align: center;
}
.section--suits {
    background-image: url("/static/images/icons/icon-logo.svg");
    background-position: -130px 102%;
    background-repeat: no-repeat;
    background-size: 600px auto;
    padding-bottom: 30px;
    padding-top: 230px;
    position: relative;
    @media (min-width: 768px) {
        background-position: -200px 120%;
        background-size: 100% auto;
        padding-bottom: 80px;
    }
    &::before {
        background-color: #fff;
        box-shadow: 0 -60px 40px -30px  rgba(0, 0, 0, 0.06);
        content: "";
        height: 100px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: skewY(12deg);
        width: 100%;
    }
    .section__narrow {
        max-width: 1000px;
        position: relative;
    }
}
.section__footer {
    text-align: center;
}
.section__title {
    color: #161d33;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 36px;
    margin: 0 0 30px;
    text-align: center;
    text-transform: uppercase;
    @media (min-width: @tablet) {
        font-size: 40px;
        line-height: 50px;
        margin: 0 0 40px;
        text-align: left;
    }
    &--aboutInfo {
        margin: 0 0 20px;
        @media (min-width: 768px) {
            margin: 0 0 40px;
        }
    }
}

// Testimonial Section
.section--testimonials {
    background-color: #f2f4f4;
    background-image: url("/static/images/icons/icon-logo.svg");
    background-position: 110% 100%;
    background-repeat: no-repeat;
    // background-size: 300px auto;
    padding: 45px 0;
    @media (min-width: 768px) {
        padding: 60px 0 80px;
    }
}

// About Section
.section--about {
    padding: 45px 0;
    @media (min-width: 768px) {
        padding: 60px 0;
    }
}
.section__title--aboutInfo {
    margin-bottom: 25px;
}
.aboutInfo {
    @media (min-width: 768px) {
        align-items: center;
        display: grid;
        grid-gap: 45px;
        grid-template-columns: 450px auto;
    }
}
.aboutInfo__image {
    margin-bottom: 25px;
    img {
        display: block;
        height: auto;
        margin: auto;
        max-width: 100%;
        width: auto;
    }
}
.aboutInfo__readMoreVisual {
    text-align: center;
    @media (min-width: 768px) {
        text-align: left;
    }
}