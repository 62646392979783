.cards--suits {
    @media (min-width: 768px) {
        column-count: 2;
        column-gap: 30px;
        margin-top: 60px;
        .template-homepage & {
            margin-top: 0;
        }
    }
    &__footer {
        display: inline-block;
        margin: 40px 0;
        text-align: center;
        width: 100%;
    }
}
.cards__header {
    margin-top: -175px;
    @media (min-width: 768px) {
        margin-bottom: 50px;
        margin-top: -200px;
    }
}
.card--suit {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.20);
    display: inline-block;
    margin: 0 0 30px;
    min-height: 400px;
    overflow: hidden;
    position: relative;
    transition: 0.3s ease-out;
    width: 100%;
    @media (min-width: 768px) {
        box-shadow: none;
    }
    &:hover {
        background: #fff;
        box-shadow: 0 0 35px rgba(0, 0, 0, 0.18);
        @media (min-width: 768px) {
            transform: translateY(-10px);
        }
        .card__img {
            transform: scale(1.02);
        }
    }
}
.card__visual {
    overflow: hidden;
}
.card__img {
    display: block;
    height: auto;
    margin: auto;
    transition: 0.3s ease-out;
    width: 100%;
}
.card__header {
    padding: 30px 30px 45px;
}
.card__type {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.card__title {
    font-size: 21px;
    line-height: 26px;
}
.card__link {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
