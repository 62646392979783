.page__footer {
    position: relative;
    abbr {
        color: #fff;
    }
    .navbar {
        color: #fff;
        display: grid;
        grid-template-columns: 1fr;
        @media (min-width: @tablet) {
            grid-template-columns: auto auto;
        }
    }
    .logo {
        order: 1;
        text-align: center;
        padding: 0;
        @media (min-width: @tablet) {
            text-align: left;
            order: 0;
        }
    }
}
.container--footer {
    @media (min-width: 768px) {
        display: grid;
        grid-gap: 200px;
        grid-template-columns: 5fr 2fr;
    }
}
.footer__contact {
    padding: 20px 0 0;
    @media (min-width: 768px) {
        align-items: center;
        background-image: url("/static/images/suit-boichuk.png");
        background-position: 60% calc(100% - 150px);
        background-repeat: no-repeat;
        background-size: auto calc(100% - 150px);
        display: flex;
        margin-bottom: -150px;
    }
    .template--form & {
        display: none;
    }
    h2 {
        text-align: center;
        margin: 0 0 30px;
        @media (min-width: @tablet) {
            margin: 0 0 50px 40px;
            text-align: left;
        }
    }
}
.form--contact {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    padding: 30px 40px 40px;
}

.btnContactForm {
    background-color: #df194e;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: block;
    font-family: @sans-serif-fonts;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 4px;
    line-height: 24px;
    margin: 50px auto 0;
    padding: 12px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    width: 240px;
}
.footer__formInfo {
    padding: 40px 0 20px;
    text-align: center;
    @media (min-width: @tablet) {
        align-items: center;
        display: flex;
        padding: 0 0 150px;
        text-align: left;
    }
}
.contact__info {
    svg {
        margin: 0 0 18px;
    }
}
.contact__phone {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.12em;
    line-height: 124.4%;
    text-decoration: none;
    svg {
        margin-right: 10px;
    }
}
.footer__nav {
    background-color: #161d33;
    clear: both;
    font-weight: 300;
    padding: 40px 0;
    @media (min-width: @tablet) {
        padding: 190px 0 52px;
    }
    .template--form & {
        padding: 52px 0;
    }
    nav {
        display: none;
        @media (min-width: @tablet) {
            display: block;
        }
    }
}
.footer__connect {
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    @media (min-width: 768px) {
        float: right;
        margin-top: -125px;
    }
    .template--form & {
        @media (min-width: @tablet) {
            display: none;
        }
    }
}
.colophon {
    margin: 30px 0;
    order: 0;
    text-align: center;
    @media (min-width: @tablet) {
        margin: 0;
        text-align: left;
        order: 1;
    }
}