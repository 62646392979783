.photo__circle_suit {
    background-color: #f0f0f0;
    padding: 1rem;
}
.threesixty {
    cursor: grab;
    margin: 0 auto;
    position: relative;
    user-select: none;
}
.threesixty::after {
    background-image: url("/static/images/360.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 50px auto;
    content: "";
    height: 40px;
    left: 10px;
    opacity: 0.2;
    position: absolute;
    top: 10px;
    width: 50px;
}
.threesixty:active {
    cursor: grabbing;
}
.g-hub {
    color: #444;
    font-size: 0.9em;
}
.buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 200px;
    width: 100%;
}
.button {
    appearance: none;
    border: none;
    cursor: pointer;
    padding: 40px 7px 5px;
    position: relative;
    &::before,
    &::after {
        border-left: 3px solid #000;
        border-top: 3px solid #000;
        content: '';
        height: 20px;
        left: 50%;
        position: absolute;
        top: 10px;
        transform: translate(-45%) rotate(-45deg);
        width: 20px;
    }
    &::after {
        transform: translate(5%) rotate(-45deg);
    }
}
#next { /* stylelint-disable-line */
    &::before {
        transform: translate(-90%) rotate(135deg);
    }
    &::after {
        transform: translate(-50%) rotate(135deg);
    }
}

// Siut
.suiteHeader {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 100%;
    margin: 0 0 40px;
    @media (min-width: @tablet) {
        grid-template-columns: 45% 2fr;
    }
}
.suiteHeaderVisual {
    display: flex;
    flex-direction: column;
    order: 0;
    @media (min-width: @tablet) {
        order: 1;
    }
}
.suiteHeaderInfo {
    flex: 1;
}
.listingConnectLinks {
    display: flex;
    list-style: none;
    margin: 0 0 25px;
    padding: 0;
    li:not(:last-child) {
        margin-right: 30px;
    }
}
.backToCollection {
    display: inline-block;
    letter-spacing: 1px;
    margin: 0 0 30px;
    text-decoration: none;
    text-transform: uppercase;
    @media (min-width: @tablet) {
        margin: 0 0 50px;
    }
    svg {
        margin: 0 15px 0 0;
    }
}
