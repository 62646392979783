// Slick slider
.slick-slider {
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
}
.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    &:focus {
        outline: none;
    }
    &.dragging {
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}
.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;
    &::before,
    &::after {
        content: "";
        display: table;
    }
    &::after {
        clear: both;
    }
    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }
    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: block;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

// Slick Arrow
.slick-arrow {
    background-color: transparent;
    border: 0;
    bottom: 50%;
    color: fade(#000, 40%);
    cursor: pointer;
    height: 59px;
    outline: none;
    padding: 0;
    position: absolute;
    width: 25px;
    z-index: 1;
    &:hover,
    &:focus {
        color: @primary-color;
    }
    &.slick-disabled {
        color: #000;
        opacity: 0.4;
    }
}
.slick-next {
    right: 0;
}
.slick-prev {
    left: 0;
}
.slick-dots {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 30px 0 0;
    padding: 0;
    button {
        background-color: #ccc;
        border-radius: 50%;
        border: 0;
        color: transparent;
        cursor: pointer;
        display: block;
        font-size: 0;
        height: 10px;
        line-height: 0;
        margin: 0 4px;
        outline: none;
        overflow: hidden;
        padding: 0;
        width: 10px;
    }
    .slick-active {
        button {
            background-color: @primary-color;
        }
    }
}
.suitHero__slider {
    order: 1;
    @media (min-width: @tablet) {
        order: 0;
    }
    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}

// Slick Hero
.slick-next--suitHero {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    left: auto;
    outline: none !important;
    padding: 0;
    position: absolute;
    right: -15px;
    top: calc(50% - 45px);
    &:hover,
    &:focus,
    &:active {
        outline: none !important;
    }
    img {
        outline: none !important;
        &:hover,
        &:focus,
        &:active {
            outline: none !important;
        }
    }
}

// Hero Nav slider
.suitHero__sliderNav {
    display: none;
    @media (min-width: @tablet) {
        display: block;
    }
    .slick-track {
        margin-left: -15px;
        margin-right: -15px;
    }
    .slick-slide {
        margin: 0 15px;
        transition: 0.5s ease;
    }
    .slick-slide img {
        height: auto;
        max-width: 100%;
    }
    .slick-current {
        display: none;
    }
}

// Testiomonials slider
.sliderTestimonial {
    @media (min-width: @tablet) {
        padding: 0 50px;
    }
    .slick-list {
        @media (min-width: @tablet) {
            margin: 0 -15px;
        }
    }
}
.sliderTestimonial__item {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px fade(#000, 10%);
    padding: 30px;
    text-align: center;
    @media (min-width: @tablet) {
        margin: 0 15px;
    }
    @media (min-width: @desktop) {
        display: grid !important;
        grid-gap: 30px;
        grid-template-columns: 100px auto;
        text-align: left;
    }
}
.sliderTestimonial__item__image {
    border-radius: 50%;
    height: 100px;
    margin: 0 auto 25px;
    overflow: hidden;
    width: 100px;
}
.sliderTestimonial__item__position {
    color: fade(#000, 40%);
    margin: 0 0 15px;
}
