// Typography resets
div,
dl,
dt,
dd,
ul,
ol,
li,
h3,
h4,
h5,
h6,
pre,
form,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}

// Paragraphs
p {
    line-height: @paragraph-lineheight;
    margin: 0 0 25px;
    text-rendering: @paragraph-text-rendering;
}

// Emphasized text
em,
i {
    font-style: italic;
    line-height: inherit;
}

// Strong text
strong,
b {
    font-weight: 700;
    line-height: inherit;
}

// Small text
small {
    font-size: @small-font-size;
    line-height: inherit;
}

// Headings
h1 {
    color: @header-color;
    font-family: @header-font-family;
    letter-spacing: 1.5px;
    line-height: 1.2;
    margin: 0 0 25px;
    text-rendering: @header-text-rendering;
    text-align: center;
    .suiteHeaderInfo & {
        text-align: left;
    }
    small {
        color: @header-small-font-color;
        line-height: 0;
    }
}

// Links
a {
    color: @anchor-color;
    cursor: pointer;
    line-height: inherit;
    text-decoration: @anchor-text-decoration;
    transition: 0.3s;
    &:hover,
    &:focus {
        color: @anchor-color-hover;
    }
    img {
        border: 0;
    }
}
.btn {
    background-color: @primary-color;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 20px;
    padding: 12px 30px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s;
    &:hover,
    &:focus {
        background-color: fade(@primary-color, 75%);
        color: #fff;
    }
    &--close {
        background: transparent;
        border-radius: 50%;
        border: 0;
        color: @global-font-color;
        cursor: pointer;
        float: right;
        height: 50px;
        line-height: 1;
        margin: 0 0 20px 20px;
        padding: 5px;
        transition: 0.15s ease;
        width: 50px;
        &:hover {
            background-color: @primary-color;
            color: #fff;
        }
    }
}

// Horizontal rule
hr {
    border-bottom: 1px solid @medium-gray;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    clear: both;
    height: 0;
    margin: 1rem auto;
    max-width: @global-width;
}

// Lists
ul,
ol,
dl {
    margin-bottom: @paragraph-margin-bottom;
}

// List items
li {
    font-size: inherit;
}

// Unordered lists
ul {
    list-style-type: disc;
    margin-left: 1.25rem;
}

// Ordered lists
ol {
    margin-left: 1.25rem;
}

// Nested unordered/ordered lists
ul, ol {
    & & {
        margin-bottom: 0;
        margin-left: 1.25rem;
    }
}
.soclink {
    display: flex;
    list-style: none;
    margin: 0 0 0 25px;
    padding: 0;
    &__item {
        align-items: center;
        display: flex;
        margin-left: 15px;
        &__link {
            display: flex;
        }
    }
    svg {
        &:hover {
            opacity: 0.4;
        }
    }
}

// Definition lists
dl {
    margin-bottom: 1rem;
    dt {
        font-weight: 700;
        margin-bottom: 0.3rem;
    }
}

// Blockquotes
blockquote {
    border-left: 1px solid @medium-gray;
    margin: 0 0 @paragraph-margin-bottom;
    padding: 0.5625rem 1.25rem 0 1.1875rem;
    &, p {
        color: @dark-gray;
        line-height: @paragraph-lineheight;
    }
}

// Citations
cite {
    color: @dark-gray;
    display: block;
    font-size: 0.82rem;
    &::before {
        content: '\2014 \0020';
    }
}

// Abbreviations
abbr {
    border-bottom: 1px dotted @black;
    color: @global-font-color;
    cursor: help;
}

// Code
code {
    background-color: @light-gray;
    border: 1px solid @medium-gray;
    color: @black;
    font-family: @font-family-monospace;
    font-weight: 400;
    padding: 0.125rem 0.3125rem 0.0625rem;
}

// Keystrokes
kbd {
    background-color: @light-gray;
    color: @black;
    font-family: @font-family-monospace;
    margin: 0;
    padding: 0.125rem 0.25rem 0;
}
.hiddenStructure {
    display: none;
}
